//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3864%3A12836&t=xwXaLDTUmv97GJfu-4

/* eslint-disable max-len */
import React from 'react';
import { ReactComponent as IconMockUp } from 'assets/icons/icon-mockup.svg';

const GridTwoBG = () => {
	return (
		<div className="bg-quinary-light py-4 py-lg-6">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<h2 className="mb-0">Things you can do</h2>
						<p className="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.Quisque bibendum diam id nibh rutrum, non tristique mi iaculis.Fusce ut mattis tellus.Nunc eu augue purus.</p>
					</div>
				</div>
				<div className="row pt-4 g-0">
					<div className="col-lg-6 pe-lg-2 mt-3 mt-lg-0">
						<div className="bg-white p-3 h-100 d-flex flex-column align-items-start">
							<IconMockUp className="mt-3 mx-0 icon-card-size" />
							<h4 className="py-3 mb-0">Track your claim</h4>
							<p>View latest dental fee schedules.</p>
							<a href="#" className="btn btn-outline-primary mt-auto">Member Login</a>
						</div>
					</div>
					<div className="col-lg-6 ps-lg-2 mt-3 mt-lg-0">
						<div className="bg-white p-3 h-100 d-flex flex-column align-items-start">
							<IconMockUp className="mt-3 mx-0 icon-card-size" />
							<h4 className="py-3 mb-0">Get your proof of insurance</h4>
							<ul>
								<li>Bullet 1</li>
								<li>Bullet 2</li>
							</ul>
							<a href="#" className="btn btn-outline-primary mt-auto">Get my card</a>
						</div>
					</div>
				</div>
				<div className="row pt-4">
					<div className="col-lg-12">
						<a href="#" className="btn btn-primary">Compare all plans</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GridTwoBG;

