//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4456%3A30747&t=xwXaLDTUmv97GJfu-4

import React from 'react';

const StepByStep = () => {
	return (
		<div className="container">
			<div className="row my-4">
				<div className="col-lg-6">
					<h3 className="mb-0">Here’s an example of how it works</h3>
					<p className="mt-2">(Based on an annual plan maximum of $1,000)</p>
					<div className="d-flex">
						<span className="circle">1</span>
						<p>If you use less than $500 of the annual maximum, then the $350 rollover amount is applied to the next year</p>
					</div>
					<div className="d-flex">
						<span className="circle">2</span>
						<p>In year two, your total benefit is now the $1,000 maximum + $350 in rollover dollars, or $1,350</p>
					</div>
					<div className="d-flex">
						<span className="circle">3</span>
						<p>In year three (if you qualify again in year two), your benefit level becomes $1,350 + $350 = $1,700</p>
					</div>
					<div className="d-flex">
						<span className="circle">4</span>
						<p>Your annual maximum plus rollover dollars can ultimately add up to $2,000 in plan benefits—the total amount the plan will pay for your dental care</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StepByStep;



