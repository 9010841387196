import React from 'react';
import { Page } from 'components/core';
import { ImgTxtCTABG, ContactUSHeader, SpecialContact, ContactTextArea, GeneralContact } from 'screens/components';

const ContactPage = () => {

	return (
		<Page>
			<ContactUSHeader />
			<ContactTextArea />
			<SpecialContact />
			<GeneralContact />
			<ImgTxtCTABG />
		</Page>
	);
};

export default ContactPage;
