//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4456%3A30705&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconRightArrow } from 'assets/icons/icon-arrow-right.svg';
const DocumentTableWithHeadings = () => {
	return (
		<div className="container py-4 px-0 px-md-3">
			<div className="row">
				<div className="col-lg-12">
					{/* Desktop table view */}
					<table className="table table-striped table-desktop">
						<thead>
							<tr>
								<th scope="col"
									className="px-3 align-text-top">
									<h4>Maximum Benefit Amount</h4>
									<p className="fs-6">If your plan’s annual maximum benefit amount is:</p>
								</th>
								<th scope="col"
									className="px-3 align-text-top">
									<h4>Claims</h4>
									<p className="fs-6">And, if your claims don’t exceed this amount:</p>
								</th>
								<th scope="col"
									className="px-3 align-text-top">
									<h4>Roll Over</h4>
									<p className="fs-6">We’ll roll over this amount to use next year and beyond:</p>
								</th>
								<th scope="col"
									className="px-3 align-text-top">
									<h4>Max Roll Over</h4>
									<p className="fs-6">You can earn up to this amount of rollover dollars:</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="p-3">Table Copy</td>
								<td className="p-3">Table Copy</td>
								<td className="p-3">Table Copy</td>
								<td className="p-3">Table Copy</td>
							</tr>
							<tr>
								<td className="p-3">Table Copy</td>
								<td className="p-3">Table Copy</td>
								<td className="p-3">Table Copy</td>
								<td className="p-3">Table Copy</td>
							</tr>
							<tr>
								<td className="p-3">Table Copy</td>
								<td className="p-3">Table Copy</td>
								<td className="p-3">Table Copy</td>
								<td className="p-3">Table Copy</td>
							</tr>
							<tr>
								<td className="p-3">Table Copy</td>
								<td className="p-3">Table Copy</td>
								<td className="p-3">Table Copy</td>
								<td className="p-3">Table Copy</td>
							</tr>
						</tbody>
					</table>
					{/* Mobile table view */}
					<p className="text-center fst-italics text-gray-700 mb-0 table-mobile border-top border-gray-200">Swipe contents to view
						<IconRightArrow className="mt-n1" />
					</p>
					<div className="table-responsive table-mobile">
						<table className="table table-bordered border-gray-200 align-middle caption-top">
							<thead>
								<tr>
									<th
										className="p-3 align-text-top bg-light min-width-10">
										<h4>Maximum Benefit Amount</h4>
										<p className="fs-6">If your plan’s annual maximum benefit amount is:</p>
									</th>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
								</tr>
								<tr>
									<th
										className="p-3 align-text-top bg-light min-width-10">
										<h4>Claims</h4>
										<p className="fs-6">And, if your claims don’t exceed this amount:</p>
									</th>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
								</tr>
								<tr>
									<th
										className="p-3 align-text-top bg-light min-width-10">
										<h4>Roll Over</h4>
										<p className="fs-6">We’ll roll over this amount to use next year and beyond:</p>
									</th>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
								</tr>
								<tr>
									<th
										className="p-3 align-text-top bg-light min-width-10">
										<h4>Max Roll Over</h4>
										<p className="fs-6">You can earn up to this amount of rollover dollars:</p>
									</th>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
									<td className="p-3 align-middle min-width-10">Table Copy</td>
								</tr>
							</thead>
						</table>
					</div>
				</div>
			</div>
		</div >
	);
};

export default DocumentTableWithHeadings;

