//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3816%3A9497&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconOpenInNew } from 'assets/icons/icon-open-in-new.svg';
import { ReactComponent as IconMockUp } from 'assets/icons/icon-mockup.svg';

const TabbedGrid = () => {
	return (
		<div className="container pb-6">
			<div className="row g-0">
				<div className="col-lg-9">
					<h2>Title Title Title</h2>
					<p className="py-3 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
				</div>
			</div>
			<div className="row pt-4 g-0">
				<div className="col-lg-4 pe-lg-2 mt-3 mt-lg-0">
					<div className="bg-white h-100 d-flex flex-column align-items-start border border-gray-200 px-3">
						<IconMockUp className="mt-4 mx-0 icon-card-size" />
						<h4 className="py-3 mb-0">Title</h4>
						<p className="">A brief description about the form.</p>
						<a href="#" className="text-decoration-none pb-4 mb-n2">
							<h6>
								View form
								<IconOpenInNew className="ms-2" />
							</h6>
						</a>
					</div>
				</div>
				<div className="col-lg-4 px-lg-2 mt-3 mt-lg-0">
					<div className="bg-white h-100 d-flex flex-column align-items-start border border-gray-200 px-3">
						<IconMockUp className="mt-4 mx-0 icon-card-size" />
						<h4 className="py-3 mb-0">Title</h4>
						<p className="">A brief description about the form.</p>
						<a href="#" className="text-decoration-none pb-4 mb-n2">
							<h6>
								View form
								<IconOpenInNew className="ms-2" />
							</h6>
						</a>
					</div>
				</div>
				<div className="col-lg-4 ps-lg-2 mt-3 mt-lg-0">
					<div className="bg-white h-100 d-flex flex-column align-items-start border border-gray-200 px-3">
						<IconMockUp className="mt-4 mx-0 icon-card-size" />
						<h4 className="py-3 mb-0">Title</h4>
						<p className="">A brief description about the form.</p>
						<a href="#" className="text-decoration-none pb-4 mb-n2">
							<h6>
								View form
								<IconOpenInNew className="ms-2" />
							</h6>
						</a>
					</div>
				</div>
			</div>
			<div className="row pt-4 g-0">
				<div className="col-lg-6 pe-lg-2 mt-3 mt-lg-0">
					<div className="bg-white h-100 d-flex flex-column align-items-start border border-gray-200 px-3">
						<IconMockUp className="mt-4 mx-0 icon-card-size" />
						<h4 className="py-3 mb-0">Title</h4>
						<p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
						<div className="d-flex flex-columnn">
							<a href="#" className="btn btn-primary me-2 mt-auto mb-3">Learn More</a>
							<a href="#" className="btn btn-outline-primary ms-2 mt-auto mb-3">Learn More</a>
						</div>
						<a href="#" className="text-decoration-none pb-4 mb-n2">
							<h6>
								View form
								<IconOpenInNew className="ms-2" />
							</h6>
						</a>
					</div>
				</div>
				<div className="col-lg-6 ps-lg-2 mt-3 mt-lg-0">
					<div className="bg-white h-100 d-flex flex-column align-items-start border border-gray-200 px-3">
						<IconMockUp className="mt-4 mx-0 icon-card-size" />
						<h4 className="py-3 mb-0">Title</h4>
						<p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
						<div className="d-flex flex-columnn">
							<a href="#" className="btn btn-primary me-2 mt-auto mb-3">Learn More</a>
							<a href="#" className="btn btn-outline-primary ms-2 mt-auto mb-3">Learn More</a>
						</div>
						<a href="#" className="text-decoration-none pb-4 mb-n2">
							<h6>
								View form
								<IconOpenInNew className="ms-2" />
							</h6>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TabbedGrid;

