import React from 'react';
import { Page } from 'components/core';
import { ImgTxtCTABG, ImgLeftTxtCTA, FindADentistHeader, FindADentistDetails } from 'screens/components';

const FindADentistLearnMorePage = () => {

	return (
		<Page>
			<FindADentistHeader />
			<FindADentistDetails />
			<ImgLeftTxtCTA />
			<ImgTxtCTABG />
		</Page>
	);
};

export default FindADentistLearnMorePage;
