//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3760%3A8723&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconLocation } from 'assets/icons/icon-location.svg';
import { ReactComponent as IconPhone } from 'assets/icons/icon-phone.svg';
import { ReactComponent as IconVerified } from 'assets/icons/icon-verified.svg';

const FindADentistGridCard = () => {
	return (

		<div className="card">
			<div className="card-body p-4">
				<h4 className="card-title mb-3">Dentist name</h4>
				<ul className="list-unstyled list-inline-item mb-0">
					<li className="list-inline-item ">
						<p className="bg-quinary-light p-2 text-secondary">Accepting patients</p>
					</li>
					<li className="list-inline-item ">
						<p className="bg-gray-100 text-gray-800 p-2">
							<IconVerified className="icon-size-20 me-1" />General</p>
					</li>
				</ul>
				<p className="mb-0"><IconPhone className="me-2" /><a className="mt-3" href="tel:123-456-7890">123-456-7890</a></p>
				<p className="mb-0 pt-3"><IconLocation className="zip-code-svg pe-2" /><span className="fw-bold">County: </span>Bakery</p>
				<p className="ms-4"><a href="/">60 West BLVD MacClenny, Florida 32063</a></p>
				<a href="/find-a-dentist-learn-more" className="btn btn-outline-primary card-link">Learn more</a>
			</div>
		</div>
	);
};

export default FindADentistGridCard;

