//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4413%3A28955&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconPhone } from 'assets/icons/icon-phone.svg';

const ProvidersContact = () => {
	return (
		<div className="container">
			<div className="row pt-4 pt-lg-5">
				<div className="col-lg-6 mb-4 mb-lg-0">
					<h2 className="mb-3">Title goes in this space</h2>
					<p>
						<ul>
							<li className="mb-3">Type of plan here</li>
							<li className="mb-3">Type of plan here</li>
							<li className="mb-3">Type of plan here</li>
							<li className="mb-3">Type of plan here</li>
						</ul>
					</p>
				</div>
				<div className="col-lg-6 mb-4 mb-lg-0">
					<h3 className="mt-2 mb-3">Customer Services</h3>
					<IconPhone className="me-2" />
					<a className="my-3" href="tel:123-456-7890">123-456-7890</a>
				</div>
				<hr className="mt-4" />
			</div>
			<div className="row pt-4 pt-lg-5">
				<div className="col-lg-6 mb-4 mb-lg-0">
					<h2 className="mb-3">Title goes in this space</h2>
					<p>
						<ul>
							<li className="mb-3">Type of plan here</li>
							<li className="mb-3">Type of plan here</li>
							<li className="mb-3">Type of plan here</li>
							<li className="mb-3">Type of plan here</li>
						</ul>
					</p>
				</div>
				<div className="col-lg-6 mb-4 mb-lg-0">
					<h3 className="mt-2 mb-3">Customer Services</h3>
					<IconPhone className="me-2" />
					<a className="my-3" href="tel:123-456-7890">123-456-7890</a>
				</div>
				<hr className="mt-4" />
			</div>
		</div>
	);
};

export default ProvidersContact;

