import React from 'react';
import { Page } from 'components/core';
import { ImgTxtCTABG, ImgLeftTxtCTA, FindADentistHeader, FindADentistGrid } from 'screens/components';

const FindADentistPage = () => {

	return (
		<Page>
			<FindADentistHeader />
			<FindADentistGrid />
			<ImgLeftTxtCTA />
			<ImgTxtCTABG />
		</Page>
	);
};

export default FindADentistPage;
