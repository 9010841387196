//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3762%3A12124&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconChevronRight } from 'assets/icons/icon-chevron-right-black.svg';
import { ReactComponent as IconChevronLeft } from 'assets/icons/icon-chevron-left.svg';
import { ReactComponent as IconList } from 'assets/icons/icon-list.svg';
import FindADentistGridCard from './FindADentistGridCard';

const FindADentistMapGrid = () => {
	return (
		<div className=" bg-gray-100">
			<div className="container">
				<div className="row g-0">
					<div className="col-lg-12 pt-4">
						<ul className="list-unstyled list-inline-item">
							<li className="list-inline-item"><h4>Filters:</h4></li>
							<li className="list-inline-item">
								<select className="form-select" aria-label="">
									<option selected={true}>Specialities</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</li>
							<li className="list-inline-item">
								<select className="form-select" aria-label="">
									<option selected={true}>Accepting patients</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</li>
							<li className="list-inline-item">
								<select className="form-select" aria-label="">
									<option selected={true}>Gender</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</li>
							<li className="list-inline-item">
								<select className="form-select" aria-label="">
									<option selected={true}>Availability</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</li>
							<li className="list-inline-item">
								<select className="form-select" aria-label="">
									<option selected={true}>Accessibility</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</li>
							<li className="list-inline-item ">
								<a href="find-a-dentist" className="btn btn-outline-primary px-2"><IconList /> List view</a>
							</li>
						</ul>
						<hr />
					</div>
				</div>
			</div>
			<div className="container-fluid">
				<div className="row pt-4 g-4">
					<div className="col-lg-6 col-sm-12">
						<ul className="list-unstyled ms-0 ms-md-4">
							<li className="mb-3">
								<FindADentistGridCard />
							</li>
							<li className="mb-3">
								<FindADentistGridCard />
							</li>
						</ul>
						<nav aria-label="Page navigation example">
							<ul className="pagination ms-4">
								<li className="page-item">
									<a className="page-link" href="#" aria-label="Previous">
										<span aria-hidden="true"><IconChevronLeft /></span>
									</a>
								</li>
								<li className="page-item"><a className="page-link h6" href="#">1</a></li>
								<li className="page-item"><a className="page-link h6" href="#">2</a></li>
								<li className="page-item"><a className="page-link h6" href="#">3</a></li>
								<li className="page-item">
									<a className="page-link" href="#" aria-label="Next">
										<span aria-hidden="true"><IconChevronRight /></span>
									</a>
								</li>
							</ul>
						</nav>
					</div>
					<div className="col-lg-6">
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83327.54691329089!2d-123.19394337998577!3d49.2577142816109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548673f143a94fb3%3A0xbb9196ea9b81f38b!2sVancouver%2C%20BC!5e0!3m2!1sen!2sca!4v1673646592552!5m2!1sen!2sca" width="100%" height="100%" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FindADentistMapGrid;

