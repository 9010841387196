import React from 'react';
import { Route, Routes } from 'react-router-dom';

//#region component imports
import { PageFooter } from './components/core';
import FourOhFourPage from './screens/404Page';
import FourOhOnePage from './screens/401Page';

import LandingPage from './screens/LandingPage';
import ProvidersLandingPage from './screens/ProvidersLandingPage';
import ProvidersToolsAndResourcePage from './screens/ProvidersToolsAndResourcePage';
import MembersLandingPage from './screens/MembersLandingPage';
import MembersPlanPage from './screens/MembersPlanPage';
import MembersProgramPage from './screens/MembersProgramPage';
import MembersToolsAndResourcePage from './screens/MembersToolsAndResourcePage';
import ResourcePage from './screens/ResourceLandingPage';
import ContactUsPage from './screens/ContactPage';
import SpecialComponentsPage from './screens/SpecialComponents';
import ContactUsPageProvider from './screens/ContactPageProvider';
import ZipCodePage from './screens/ZipCodePage';
import SearchResultsPage from './screens/SearchResultsPage';
import FindADentistPage from './screens/FindADentistPage';
import FindADentistLearnMorePage from './screens/FindADentistLearnMorePage';
import FindADentistMapPage from './screens/FindADentistMapPage';
import FindADentistMapLearnMorePage from './screens/FindADentistMapLearnMorePage';



const AppNavigator: React.FC = () => {

	return (
		<React.Fragment>
			<Routes>
				<Route path="/" element={<LandingPage />} />
				<Route path="/providers" element={<ProvidersLandingPage />} />
				<Route path="/providers-tools" element={<ProvidersToolsAndResourcePage />} />
				<Route path="/members" element={<MembersLandingPage />} />
				<Route path="/members-plan" element={<MembersPlanPage />} />
				<Route path="/members-program" element={<MembersProgramPage />} />
				<Route path="/members-tools" element={<MembersToolsAndResourcePage />} />
				<Route path="/resource" element={<ResourcePage />} />
				<Route path="/contact-us" element={<ContactUsPage />} />
				<Route path="/speciality" element={<SpecialComponentsPage />} />
				<Route path="/contact-us-providers" element={<ContactUsPageProvider />} />
				<Route path="/zip-code" element={<ZipCodePage />} />
				<Route path="/search" element={<SearchResultsPage />} />
				<Route path="/find-a-dentist" element={<FindADentistPage />} />
				<Route path="/find-a-dentist-learn-more" element={<FindADentistLearnMorePage />} />
				<Route path="/find-a-dentist-map" element={<FindADentistMapPage />} />
				<Route path="/find-a-dentist-map-learn-more" element={<FindADentistMapLearnMorePage />} />
				<Route path="401" element={<FourOhOnePage />} />
				<Route path="*" element={<FourOhFourPage />} />
			</Routes>
			<PageFooter />
		</React.Fragment>
	);
};

export default AppNavigator;