import React from 'react';
import { Page } from 'components/core';
import { DocumentTableWithHeadings, FAQLight, FAQDark, Quote, Alerts, StepByStep, ExternalLink, DocumentTable, DocumentTableWithFilter } from 'screens/components';

const SpecialComponentsPage = () => {

	return (
		<Page>
			<DocumentTableWithHeadings />
			<DocumentTableWithFilter />
			<DocumentTable />
			<Quote />
			<ExternalLink />
			<Alerts />
			<StepByStep />
			<FAQLight />
			<FAQDark />
		</Page>
	);
};

export default SpecialComponentsPage;
