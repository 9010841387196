// figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3752%3A3882&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import mockupimg from 'assets/images/threeimgmockup2.png';

const ImgRightTxtCTA = () => {
	return (
		<div className="container">
			<div className="row my-4 my-lg-6 align-items-center">
				<div className="col-lg-6 mb-4 mb-lg-0">
					<h2 className="">Title title title</h2>
					<p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit.Quisque bibendum diam id nibh rutrum, non tristique mi iaculis.Fusce ut mattis tellus.Nunc eu augue purus.</p>
					<a className="btn btn-primary mt-4" href="/">Link</a>
				</div>
				<div className="col-lg-6">
					<img className="object-fit-cover" src={mockupimg} alt="This is a mock up image" />
				</div>
			</div>
		</div>

	);


};

export default ImgRightTxtCTA;

