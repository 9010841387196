import React from 'react';
import { Page } from 'components/core';
import { LandingHeader, ImgRightTxtCTA, ImgTxtCTABG, GridThree, GridThreeBG } from 'screens/components';

const MembersProgramPage = () => {

	return (
		<Page>
			<Page>
				<LandingHeader />
				<GridThree />
				<GridThreeBG />
				<ImgRightTxtCTA />
				<ImgTxtCTABG />
			</Page>
		</Page>
	);
};

export default MembersProgramPage;
