//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3915%3A11943&t=xwXaLDTUmv97GJfu-4

import React from 'react';

const ToolsAndResourceHeader = () => {
	return (
		<div className="bg-tertiary-light pb-4">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<h2 className="mt-4">Tools and resources</h2>
						<p className="mt-1 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
						<label htmlFor="header-search" className="form-label fw-bold mt-4">Search for a tool or resource</label>
						<div className="input-group mt-1">
							<input type="text" className="form-control rounded-0" id="header-search" placeholder="" aria-label="" aria-describedby="button-addon2" />
							<button className="btn btn-primary rounded-0" type="button" id="button-addon2">Search</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ToolsAndResourceHeader;

