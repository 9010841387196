import React from 'react';
import { Page } from 'components/core';
import { ImgTxtCTABG, ImgLeftTxtCTA, FindADentistHeader, FindADentistMapGrid } from 'screens/components';

const FindADentistMapPage = () => {

	return (
		<Page>
			<FindADentistHeader />
			<FindADentistMapGrid />
			<ImgLeftTxtCTA />
			<ImgTxtCTABG />
		</Page>
	);
};

export default FindADentistMapPage;
