import React from 'react';
import { Page } from 'components/core';
import { SearchResults } from 'screens/components';

const SearchResultsPage = () => {

	return (
		<Page>
			<SearchResults />
		</Page>
	);
};

export default SearchResultsPage;
