//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3915%3A11950&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import classNames from 'classnames';
import { TabbedContextTxt, RelatedArticlesTabbed, PopularDocuments, DocumentTable, DocumentTableWithFilter, TabbedGrid, Fees } from 'screens/components';

const TabbedContent = () => {

	const [tabstate, setTabState] = React.useState('overview');

	return (
		<div className="container pb-6 mt-6">
			<div className="row">
				<div className="col-lg-3">
					<div className="nav flex-column nav-pills bg-quinary-light me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical" >
						<button onClick={() => setTabState('overview')}
							className={classNames('nav-link text-dark', tabstate === 'overview' && 'active')}
							id="v-pills-1-tab"
							data-bs-toggle="pill"
							data-bs-target="#v-pills-1"
							type="button"
							role="tab"
							aria-controls="v-pills-1"
							aria-selected="true">
							Overview
						</button>
						<button onClick={() => setTabState('forms')}
							className={classNames('nav-link text-dark', tabstate === 'forms' && 'active')}
							id="v-pills-2-tab"
							data-bs-toggle="pill"
							data-bs-target="#v-pills-2"
							type="button"
							role="tab"
							aria-controls="v-pills-2"
							aria-selected="false">
							Forms and documents
						</button>
						<button onClick={() => setTabState('dental')}
							className={classNames('nav-link text-dark', tabstate === 'dental' && 'active')}
							id="v-pills-3-tab"
							data-bs-toggle="pill"
							data-bs-target="#v-pills-3"
							type="button"
							role="tab"
							aria-controls="v-pills-3"
							aria-selected="false">
							Dental insurance tips
						</button>
						<button onClick={() => setTabState('health')}
							className={classNames('nav-link text-dark', tabstate === 'health' && 'active')}
							id="v-pills-4-tab"
							data-bs-toggle="pill"
							data-bs-target="#v-pills-4"
							type="button"
							role="tab"
							aria-controls="v-pills-4"
							aria-selected="false">
							Health and wellness tips
						</button>
						<button onClick={() => setTabState('glossary')}
							className={classNames('nav-link text-dark', tabstate === 'glossary' && 'active')}
							id="v-pills-5-tab"
							data-bs-toggle="pill"
							data-bs-target="#v-pills-5"
							type="button"
							role="tab"
							aria-controls="v-pills-5"
							aria-selected="false">
							Glossary of dental plan terms
						</button>
					</div>
				</div>
				<div className="col-lg-9">
					<div className="tab-content" id="v-pills-tabContent">
						<div
							className={classNames('tab-pane bg-white pt-3 pt-lg-0', tabstate === 'overview' && 'fade show active')}
							id="v-pills-1"
							role="tabpanel"
							aria-labelledby="v-pills-1-tab">
							<TabbedContextTxt />
							<RelatedArticlesTabbed />
							<PopularDocuments />
							<DocumentTable />
							<DocumentTableWithFilter />
						</div>
						<div className={classNames('tab-pane bg-white pt-3 pt-lg-0', tabstate === 'forms' && 'fade show active')}
							id="v-pills-2"
							role="tabpanel"
							aria-labelledby="v-pills-2-tab">
							<Fees />
							<TabbedContextTxt />

						</div>
						<div className={classNames('tab-pane bg-white pt-3 pt-lg-0', tabstate === 'dental' && 'fade show active')}
							id="v-pills-3"
							role="tabpanel"
							aria-labelledby="v-pills-3-tab">
							<RelatedArticlesTabbed />
						</div>
						<div className={classNames('tab-pane bg-white pt-3 pt-lg-0', tabstate === 'health' && 'fade show active')}
							id="v-pills-4" role="tabpanel"
							aria-labelledby="v-pills-4-tab">
							<TabbedGrid />
						</div>
						<div className={classNames('tab-pane bg-white pt-3 pt-lg-0', tabstate === 'glossary' && 'fade show active')}
							id="v-pills-5"
							role="tabpanel"
							aria-labelledby="v-pills-5-tab">
							<DocumentTableWithFilter />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TabbedContent;

