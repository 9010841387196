//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4413%3A33519&t=xwXaLDTUmv97GJfu-4

import React, { useState } from 'react';
import classNames from 'classnames';

const FAQDark = () => {

	const [active, setActive] = useState<boolean>(false);
	const [active2, setActive2] = useState<boolean>(false);
	const [active3, setActive3] = useState<boolean>(false);

	return (
		<div className="bg-quinary-light">
			<div className="container">
				<div className="row py-5">
					<div className="col-lg-12">
						<h2 className="mb-4">Frequently Asked Questions</h2>
					</div>
					<div className="col-lg-8">
						<div className="accordion accordion-flush border-top border-bottom" id="accordionFlushExample">
							<div className="accordion-item">
								<h2 className="accordion-header"
									id="flush-headingOne">
									<button
										className={classNames('accordion-button', active === false && 'collapsed')}
										type="button"
										data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"
										onClick={() => setActive(active => !active)}>
										Accordion Item #1
									</button>
								</h2>
								<div id="flush-collapseOne"
									className={classNames('accordion-collapse collapse', active === true && 'show')}
									aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">Placeholder content for this accordion.</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header"
									id="flush-headingTwo">
									<button
										className={classNames('accordion-button', active2 === false && 'collapsed')}
										type="button"
										data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"
										onClick={() => setActive2(active2 => !active2)}>
										Accordion Item #2
									</button>
								</h2>
								<div id="flush-collapseTwo"
									className={classNames('accordion-collapse collapse', active2 === true && 'show')}
									aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">Placeholder content for this accordion.</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header"
									id="flush-headingThree">
									<button className={classNames('accordion-button', active3 === false && 'collapsed')}
										type="button"
										data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"
										onClick={() => setActive3(active3 => !active3)}>
										Accordion Item #3
									</button>
								</h2>
								<div id="flush-collapseThree"
									className={classNames('accordion-collapse collapse', active3 === true && 'show')} aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">Placeholder content for this accordion.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FAQDark;

