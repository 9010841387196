//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3816%3A9494&t=xwXaLDTUmv97GJfu-4

import React from 'react';

const TabbedContextTxt = () => {
	return (
		<div className="pb-4">
			<div className="container">
				<div className="row">
					<div className="col-lg-9">
						<h3 className="pb-3">Title</h3>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TabbedContextTxt;

