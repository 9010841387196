//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4413%3A28850&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconOpenInNew } from 'assets/icons/icon-open-in-new.svg';

const SpecialContact = () => {
	return (
		<div className="bg-quinary-light">
			<div className="container">
				<div className="row py-4 py-lg-6 align-items-center">
					<div className="col-lg-6 mb-4 mb-lg-0">
						<h2 className="mb-2">Special Contact Information</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
						<div className="my-4 d-flex">
							<a className="btn btn-primary me-3" href="/">Members Contacts</a>
							<a className="btn btn-primary" href="/contact-us-providers">Providers Contacts</a>
						</div>
						<div className="d-flex">
							<div className="flex-column me-5">
								<h4 className="mb-3">Agents</h4>
								<a href="#" className="h6 text-decoration-none">External Link Here <IconOpenInNew className="ms-2" /></a>
							</div>
							<div className="flex-column">
								<h4 className="mb-3">Empolyers</h4>
								<a href="#" className="h6 text-decoration-none">External Link Here  <IconOpenInNew className="ms-2" /></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SpecialContact;

