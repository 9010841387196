//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4413%3A28809&t=xwXaLDTUmv97GJfu-4

import React from 'react';

const ContactUSHeader = () => {
	return (
		<div className="bg-tertiary-light pb-4">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<h2 className="mt-4">Contact Us</h2>
						<p className="mt-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactUSHeader;

