// figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3760%3A8718&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import FindADentistCard from './FindADentistCard';


const FindADentistDetails = () => {
	return (
		<div className="pb-6 bg-gray-100">
			<div className="container">
				<div className="row pt-4 row-cols-1 row-cols-md-3 g-4">
					<div className="col-lg-12 mt-3 pe-md-2">
						<FindADentistCard />
					</div>
				</div>
			</div>
		</div>
	);
};

export default FindADentistDetails;

