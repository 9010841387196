//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4456%3A30824&t=xwXaLDTUmv97GJfu-4

/* eslint-disable max-len */
import React from 'react';

const Quote = () => {
	return (
		<div className="container">
			<div className="row mt-5 mb-4">
				<div className="col-lg-6">
					<figure className="p-3 border border-gray-200">
						<blockquote className="blockquote mt-n4">
							<p><em>Lorem ipsum dolor sit amet consectetur. Tristique ultrices morbi viverra sodales faucibus tempus diam ultrices donec. Vitae condimentum ornare sit auctor tristique interdum ac.</em></p>
						</blockquote>
						<figcaption className="mt-1 mb-0">
							<h5 className="text-dark">Johnathan Doe, Title Here</h5>
						</figcaption>
					</figure>
				</div>
			</div>
		</div>
	);
};

export default Quote;



