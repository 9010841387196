import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import NavBar from './components/navigation/NavBar';
import 'react-toastify/dist/ReactToastify.css';
import AppNavigator from 'AppNavigator';
import { ToastProvider } from 'components/customHooks/useToast';


const App: React.FC = () => {

	return (
		<ToastProvider>
			<BrowserRouter>
				<React.Fragment>
					<NavBar />
					<AppNavigator />
				</React.Fragment>
			</BrowserRouter>
		</ToastProvider>
	);
};

export default App;
