//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4569%3A32781&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';
import { ReactComponent as IconNewTab } from 'assets/icons/icon-open-in-new.svg';

const DocumentTableWithFilter = () => {
	return (
		<div className="pb-4">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<h3 className="">Title</h3>
						<select className="form-select form-select-lg border border-gray-200 mb-3 p-1 rounded-0 w-25"
							aria-label=".form-select-lg example">
							<option value="all">Filters</option>
							<option value="claims">Claims</option>
							<option value="benefits">Benefits</option>
							<option value="billing">Billing</option>
						</select>
						<ul className="nav pb-4" id="pills-tab" role="tablist">
							<li className="nav-item pe-2 mb-2 mb-md-0" role="presentation">
								<button className="nav-link bg-white tag border border-gray-200"
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true">
									Claims <IconClose />
								</button>
							</li>
							<li className="nav-item px-2 mb-2 mb-md-0" role="presentation">
								<button className="nav-link bg-white tag border border-gray-200"
									id="pills-profile-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-profile"
									type="button"
									role="tab"
									aria-controls="pills-profile"
									aria-selected="false">
									Benefits <IconClose />
								</button>
							</li>
							<li className="nav-item px-2 mb-2 mb-md-0" role="presentation">
								<button className="nav-link bg-white tag border border-gray-200"
									id="pills-contact-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-contact"
									type="button"
									role="tab"
									aria-controls="pills-contact"
									aria-selected="false">
									Billing <IconClose />
								</button>
							</li>
						</ul>
						<div className="tab-content" id="pills-tabContent">
							<div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
								{/* Desktop table view */}
								<div className="table-responsive table-desktop">
									<table className="table table-striped">
										<thead>
											<tr>
												<th scope="col">Name</th>
												<th scope="col">Tags</th>
												<th scope="col"></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="pt-3">Doc Name.doc</td>
												<td>
													<button className="btn btn-white mx-1 tag">Tag</button>
													<button className="btn btn-white mx-1 tag">Tag</button>
												</td>
												<td className="text-end"><a href="#" className="btn btn-outline-primary">View
													<IconNewTab className="icon-size-20 ms-2" /></a></td>
											</tr>
											<tr>
												<td className="pt-3">DocName.xls</td>
												<td>
													<button className="btn btn-white mx-1 tag">Tag</button>
												</td>
												<td className="text-end"><a href="#" className="btn btn-outline-primary">View
													<IconNewTab className="icon-size-20 ms-2" /></a></td>
											</tr>
											<tr>
												<td className="pt-3">Doc with a realllllllllllly long filename.pdf</td>
												<td>
													<button className="btn btn-white mx-1 tag">Tag</button>
													<button className="btn btn-white mx-1 tag">Tag</button>
												</td>
												<td className="text-end"><a href="#" className="btn btn-outline-primary">View
													<IconNewTab className="icon-size-20 ms-2" /></a></td>
											</tr>
										</tbody>
									</table>
								</div>
								{/* Mobile table view */}
								<div className="table-mobile">
									<div className="card mb-3">
										<div className="card-body p-3">
											<h5 className="card-title mb-3">Document title</h5>
											<div className="pb-3">
												<button className="btn btn-white mx-1 tag">Tag</button>
											</div>
											<a href="#" className="card-link btn btn-outline-primary">View PDF
												<IconNewTab className="icon-size-20 ms-2" /></a>
										</div>
									</div>
									<div className="card mb-3">
										<div className="card-body p-3">
											<h5 className="card-title mb-3">Document title</h5>
											<div className="pb-3">
												<button className="btn btn-white mx-1 tag">Tag</button>
											</div>
											<a href="#" className="card-link btn btn-outline-primary">View PDF
												<IconNewTab className="icon-size-20 ms-2" /></a>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"></div>
							<div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DocumentTableWithFilter;

