import React from 'react';
import { Page } from 'components/core';
import { ImgTxtCTABG, ContactUSHeader, ProvidersContact } from 'screens/components';

const ContactPageProvider = () => {

	return (
		<Page>
			<ContactUSHeader />
			<ProvidersContact />
			<ImgTxtCTABG />
		</Page>
	);
};

export default ContactPageProvider;
