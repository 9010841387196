//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3915%3A11953&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconChevronRight } from 'assets/icons/icon-chevron-right.svg';

const RelatedArticlesTabbed = () => {
	return (
		<div className="pb-6">
			<div className="container">
				<div className="row g-0">
					<div className="col-lg-8">
						<h3>Related articles</h3>
					</div>
					<div className="col-lg-4 text-lg-end">
						<a href="/" className="text-decoration-none"><h5>View all resources</h5></a>
					</div>
				</div>
				<div className="row pt-3 row-cols-1 row-cols-md-3 g-4">
					<div className="col pe-2">
						<div className="card">
							<img src="https://picsum.photos/372/278?random=1" className="h-100" alt="" />
							<div className="card-body p-3">
								<h5 className="card-title">Article title</h5>
								<p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
								<a href="#" className="card-link">Read more <IconChevronRight /></a>
							</div>
						</div>
					</div>
					<div className="col px-2">
						<div className="card">
							<img src="https://picsum.photos/372/278?random=2" className="h-100" alt="" />
							<div className="card-body p-3">
								<h5 className="card-title">Article title</h5>
								<p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
								<a href="#" className="card-link">Read more <IconChevronRight /></a>
							</div>
						</div>
					</div>
					<div className="col ps-2">
						<div className="card">
							<img src="https://picsum.photos/372/278?random=3" className="h-100" alt="" />
							<div className="card-body p-3">
								<h5 className="card-title">Article title</h5>
								<p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
								<a href="#" className="card-link">Read more <IconChevronRight /></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	);


};

export default RelatedArticlesTabbed;

