// figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3754%3A7075&t=xwXaLDTUmv97GJfu-4

import React from 'react';

const FindADentistHeader = () => {
	return (
		<div className="bg-tertiary-light pb-4">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<h2 className="mt-4">Find a dentist</h2>
						<p className="mt-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<label>Your plan</label>
						<select className="form-select rounded-0 mt-2" id="form-select" aria-label="Default select example">
							<option selected={true}>Select a plan</option>
							<option value="1">One</option>
							<option value="2">Two</option>
							<option value="3">Three</option>
						</select>
					</div>
					<div className="col-md-4 pt-3 pt-md-0">
						<label>Location</label>
						<div className="input-group mb-3 mt-2">
							<input type="text" className="form-control rounded-0" placeholder="Enter zip code, county or address" aria-label="" aria-describedby="" />
						</div>
					</div>
					<div className="col-md-4">
						<label>Dentist Name</label>
						<div className="input-group mb-3 mt-2">
							<input type="text" className="form-control rounded-0" aria-label="" aria-describedby="" />
						</div>
					</div>
					<div className="col-lg-12 py-3">
						<button className="btn btn-primary" type="submit">Search</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FindADentistHeader;

