import React from 'react';
import { Page } from 'components/core';
import { ZipCodeHeader, ImgLeftTxtCTA, ImgTxtCTABG } from 'screens/components';

const ZipCodePage = () => {

	return (
		<Page>
			<ZipCodeHeader />
			<ImgLeftTxtCTA />
			<ImgTxtCTABG />
		</Page>
	);
};

export default ZipCodePage;
