//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3812%3A7932&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconWarning } from 'assets/icons/icon-alert-warning.svg';
const Fees = () => {
	return (
		<div className="container pb-4">
			<div className="row">
				<div className="col-lg-9">
					<h3 className="pb-3">Fee schedules</h3>
					<p>Fee schedules for all BlueDental Plans are available on a secure site through the link below. Your Type 1 NPI is required for access.</p>
				</div>
				<div className="col-lg-12">
					<div className="alert alert-warning my-3" role="alert">
						<h6 className="alert-heading fw-bold proxima-nova mb-0">
							<IconWarning className="flex-shrink-0 me-2" />
							The 2022 Fee Schedules are effective January 1, 2022.
						</h6>
					</div>
				</div>
				<div className="col-lg-4">
					<label htmlFor="textinput" className="form-label">Type 1 NPI:</label>
					<input type="text" className="form-control mb-3" id="textinput" placeholder="" />
					<div className="invalid-feedback">
						Please enter a valid NPI.
					</div>
					<button type="submit" className="btn btn-primary mt-auto">View fee schedules</button>
				</div>
			</div>
		</div>
	);
};

export default Fees;

