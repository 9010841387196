// figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3760%3A8718&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconChevronRight } from 'assets/icons/icon-chevron-right-black.svg';
import { ReactComponent as IconChevronLeft } from 'assets/icons/icon-chevron-left.svg';
import { ReactComponent as IconMap } from 'assets/icons/icon-map.svg';
import FindADentistGridCard from './FindADentistGridCard';

const FindADentistGrid = () => {
	return (
		<div className="pb-6 bg-gray-100">
			<div className="container">
				<div className="row g-0">
					<div className="col-lg-12 pt-4">
						<ul className="list-unstyled list-inline-item">
							<li className="list-inline-item"><h4>Filters:</h4></li>
							<li className="list-inline-item">
								<select className="form-select" aria-label="">
									<option selected={true}>Specialities</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</li>
							<li className="list-inline-item">
								<select className="form-select" aria-label="">
									<option selected={true}>Accepting patients</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</li>
							<li className="list-inline-item">
								<select className="form-select" aria-label="">
									<option selected={true}>Gender</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</li>
							<li className="list-inline-item">
								<select className="form-select" aria-label="">
									<option selected={true}>Availability</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</li>
							<li className="list-inline-item">
								<select className="form-select" aria-label="">
									<option selected={true}>Accessibility</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</select>
							</li>
							<li className="list-inline-item ">
								<a href="/find-a-dentist-map" className="btn btn-outline-primary px-2"><IconMap /> Map view</a>
							</li>
						</ul>
						<hr />
					</div>
				</div>
				<div className="row pt-4 row-cols-1 row-cols-md-3 g-4">
					<div className="col-lg-4 mt-3 pe-lg-2">
						<FindADentistGridCard />
					</div>
					<div className="col-lg-4 mt-3 px-lg-2">
						<FindADentistGridCard />
					</div>
					<div className="col-lg-4 mt-3 ps-lg-2">
						<FindADentistGridCard />
					</div>
					<div className="col-lg-4 mt-3 pe-lg-2">
						<FindADentistGridCard />
					</div>
					<div className="col-lg-4 mt-3 px-lg-2">
						<FindADentistGridCard />
					</div>
					<div className="col-lg-4 mt-3 ps-lg-2">
						<FindADentistGridCard />
					</div>
					<div className="col-lg-12">
						<nav aria-label="Page navigation example">
							<ul className="pagination">
								<li className="page-item">
									<a className="page-link" href="#" aria-label="Previous">
										<span aria-hidden="true"><IconChevronLeft /></span>
									</a>
								</li>
								<li className="page-item"><a className="page-link h6" href="#">1</a></li>
								<li className="page-item"><a className="page-link h6" href="#">2</a></li>
								<li className="page-item"><a className="page-link h6" href="#">3</a></li>
								<li className="page-item">
									<a className="page-link" href="#" aria-label="Next">
										<span aria-hidden="true"><IconChevronRight /></span>
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FindADentistGrid;

