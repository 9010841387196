import React from 'react';
import { Page } from 'components/core';
import { LandingHeader, ImgRightTxtCTA, ImgLeftTxtCTA, ImgTxtCTABG, GridThreeBG } from 'screens/components';

const LandingPage = () => {

	return (
		<Page>
			<LandingHeader />
			<ImgLeftTxtCTA />
			<GridThreeBG />
			<ImgRightTxtCTA />
			<ImgLeftTxtCTA />
			<ImgTxtCTABG />
		</Page>
	);
};

export default LandingPage;
