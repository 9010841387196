import React from 'react';
import { Page } from 'components/core';
import { ImgTxtCTABG, ToolsAndResourceHeader, TabbedContent } from 'screens/components';

const MembersToolsAndResourcePage = () => {

	return (
		<Page>
			<Page>
				<ToolsAndResourceHeader />
				<TabbedContent />
				<ImgTxtCTABG />
			</Page>
		</Page>
	);
};

export default MembersToolsAndResourcePage;
