//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4569%3A32758&t=xwXaLDTUmv97GJfu-4

import React from 'react';

const DocumentTable = () => {
	return (
		<div className="pb-4">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<h3 className="mb-3">Title</h3>
						<div className="table-responsive">
							<table className="table table-striped">
								<thead>
									<tr>
										<th scope="col"><h4>Name</h4></th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="pt-3">Doc Name.doc</td>
										<td className="text-end"><a href="#" className="btn btn-outline-primary">View</a></td>
									</tr>
									<tr>
										<td className="pt-3">DocName.xls</td>
										<td className="text-end"><a href="#" className="btn btn-outline-primary">View</a></td>
									</tr>
									<tr>
										<td className="pt-3">Doc with a realllllllllllly long filename.pdf</td>
										<td className="text-end"><a href="#" className="btn btn-outline-primary">View</a></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DocumentTable;

