// figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3750%3A4311&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import mockupimg from 'assets/images/threeimgmockup2.png';

const LandingHeader = () => {
	return (
		<div className="bg-tertiary-light py-4 py-lg-6">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-md-6">
						<h1 className="">Find a dentist</h1>
						<p className="mt-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.Quisque bibendum diam id nibh rutrum, non tristique mi iaculis.</p>
						<div className="d-lg-flex d-block mt-4">
							<a className="btn btn-primary me-3 align-self-start text-nowrap" href="/find-a-dentist">Find a dentist</a>
							<p className="mb-4 mb-lg-0 mt-2 mt-lg-0">Have a dental emergency? <a href="/" title="" className="text-decoration-none">Get emergency dental care.</a></p>
						</div>
					</div>
					<div className="col-md-6">
						<img className="object-fit-cover" src={mockupimg} alt="This is a mock up image" />
					</div>
				</div>
			</div>
		</div>

	);


};

export default LandingHeader;

