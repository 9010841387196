import React from 'react';
import { Page } from 'components/core';
import { LandingHeader, ImgRightTxtCTA, ImgLeftTxtCTA, ImgTxtCTABG, GridThreeBG } from 'screens/components';

const ProvidersLandingPage = () => {

	return (
		<Page>
			<Page>
				<LandingHeader />
				<ImgLeftTxtCTA />
				<GridThreeBG />
				<ImgRightTxtCTA />
				<ImgTxtCTABG />
			</Page>
		</Page>
	);
};

export default ProvidersLandingPage;
