import React from 'react';
//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4569%3A31723&t=xwXaLDTUmv97GJfu-4

import classNames from 'classnames';

const ExternalLink = () => {
	const [showModal, setShowModal] = React.useState<boolean>(false);
	return (
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<button type="button"
						className="btn btn-primary"
						data-bs-toggle="modal"
						data-bs-target="#externalLinkModal"
						onClick={() => setShowModal(true)}>
						External link
					</button>
					<div className={classNames('modal fade', showModal === true && 'd-flex show')}
						id="externalLinkModal" tabIndex={-1} aria-labelledby="externalLinkModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-body pb-0">
									<h3 className="modal-title pb-2" id="externalLinkModalLabel">You are leaving regencedental.com</h3>
									<p>You are being redirected to an external website, are you sure you want to continue?</p>
								</div>
								<div className="modal-footer justify-content-start mt-0 pt-0">
									<a href="/" className="btn btn-primary">Continue</a>
									<button type="button"
										onClick={() => setShowModal(false)}
										className="btn btn-outline-primary">Cancel</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExternalLink;



