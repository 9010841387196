//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4413%3A28813&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import mockupimg from 'assets/images/threeimgmockup2.png';
import { ReactComponent as IconPhone } from 'assets/icons/icon-phone.svg';

const ContactTextArea = () => {
	return (
		<div className="container">
			<div className="row my-4 my-lg-6 align-items-center">
				<div className="col-lg-6 mb-4 mb-lg-0">
					<h2 className="">Customer Service</h2>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					<IconPhone className="me-2" /><a className="my-3 pb-2" href="tel:123-456-7890">123-456-7890</a>
					<hr />
					<h4 className="my-3 pt-2">Secondary Question?</h4>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					<a className="btn btn-outline-primary my-3 pb-2" href="/">Link</a>
				</div>
				<div className="col-lg-6">
					<img className="object-fit-cover" src={mockupimg} alt="This is a mock up image" />
				</div>
			</div>
		</div>
	);
};

export default ContactTextArea;

