//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3791%3A8258&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconLocation } from 'assets/icons/icon-location.svg';
import { ReactComponent as IconPhone } from 'assets/icons/icon-phone.svg';
import { ReactComponent as IconVerified } from 'assets/icons/icon-verified.svg';
import { ReactComponent as IconWebsite } from 'assets/icons/icon-website.svg';
import { ReactComponent as IconPerson } from 'assets/icons/icon-person.svg';
import { ReactComponent as IconNetwork } from 'assets/icons/icon-network.svg';
import { ReactComponent as IconArrowLeft } from 'assets/icons/icon-arrow-left.svg';


const FindADentistCard = () => {
	return (
		<div className="card">
			<div className="card-body p-4">
				<a href="/find-a-dentist"><IconArrowLeft /></a>
				<h4 className="card-title my-3">Dentist name</h4>
				<ul className="list-unstyled list-inline-item mb-0">
					<li className="list-inline-item ">
						<p className="bg-quinary-light p-2 text-secondary mb-2 mb-md-0">Accepting patients</p>
					</li>
					<li className="list-inline-item">
						<p className="bg-gray-100 text-gray-800 p-2 mb-0">
							<IconVerified className="icon-size-20 me-1" />General</p>
					</li>
				</ul>
				<ul className="list-unstyled">
					<li className="my-3">
						<IconPhone className="me-2" /><a href="tel:123-456-7890">123-456-7890</a>
					</li>
					<li>
						<p className="mb-0"><IconLocation className="zip-code-svg pe-2" /><span className="fw-bold">County: </span>Bakery</p>
					</li>
					<li>
						<p className="ms-4"><a href="/">60 West BLVD MacClenny, Florida 32063</a></p>
					</li>
					<li>
						<p>
							<IconWebsite className="me-1" />
							<span className="fw-bold">Website: </span>
							<a href="#" className="">www.website.com</a>
						</p>
					</li>
					<li>
						<p>
							<IconPerson className=" me-1" />
							Male
						</p>
					</li>
					<li>
						<p>
							<IconNetwork className=" me-1" />
							<span className="fw-bold">Network: </span>
							Copayment
						</p>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default FindADentistCard;

