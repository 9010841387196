import React from 'react';
import { Page } from 'components/core';
import { LandingHeader, ImgRightTxtCTA, ImgLeftTxtCTA, ImgTxtCTABGGray, GridThree, GridTwoBG, DocumentTableWithFilter } from 'screens/components';

const MembersPlanPage = () => {

	return (
		<Page>
			<Page>
				<LandingHeader />
				<ImgLeftTxtCTA />
				<GridThree />
				<DocumentTableWithFilter />
				<ImgTxtCTABGGray />
				<GridTwoBG />
				<ImgRightTxtCTA />
				<ImgTxtCTABGGray />
			</Page>
		</Page>
	);
};

export default MembersPlanPage;
