//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3864%3A12776&t=xwXaLDTUmv97GJfu-4

/* eslint-disable max-len */
import React from 'react';
import { ReactComponent as IconPDF } from 'assets/icons/icon-pdf.svg';
import { ReactComponent as IconNewTab } from 'assets/icons/icon-open-in-new.svg';

const TxtWithQuickLinks = () => {
	return (
		<div className="container">
			<div className="row my-4 my-lg-6">
				<div className="col-lg-6 mb-4 mb-lg-0">
					<h2 className="">Heading 2</h2>
					<p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					<h3 className="pt-4">Heading 3</h3>
					<p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					<h4 className="pt-4">Heading 4</h4>
					<p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
				</div>
				<div className="col-lg-4 offset-lg-2">
					<div className="bg-quinary-light border-start border-quaternary border-4 d-flex flex-column p-4 m-n2">
						<h3 className="pb-4 mb-n2">Quick Links</h3>
						<h5 className="mb-2">a heading</h5>
						<a className="d-block mb-2 view-doc" href="#">
							<IconPDF className="me-2" />
							Benefit summary
							<IconNewTab className="icon-size-20 ms-2" />
						</a>
						<a className="d-block mb-2 view-doc" href="#">
							<IconPDF className="me-2" />
							Benefit summary
							<IconNewTab className="icon-size-20 ms-2" />
						</a>
						<a className="d-block mb-2 view-doc" href="#">
							<IconPDF className="me-2" />
							Benefit summary
							<IconNewTab className="icon-size-20 ms-2" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TxtWithQuickLinks;

