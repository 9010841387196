// figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3753%3A4142&t=xwXaLDTUmv97GJfu-4

/* eslint-disable max-len */
import React from 'react';
import mockupimg from 'assets/images/mockupimg.png';

const ImgTxtCTABG = () => {
	return (
		<div className="container">
			<div className="row g-0 my-4 my-lg-6 bg-secondary">
				<div className="col-lg-6">
					<img className="object-fit-cover" src={mockupimg} alt="This is a mock up image" />
				</div>
				<div className="col-lg-6 px-3 py-4 px-lg-5 py-lg-5">
					<h2 className="text-white">Title title title</h2>
					<p className="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.Quisque bibendum diam id nibh rutrum, non tristique mi iaculis.Fusce ut mattis tellus.Nunc eu augue purus.Donec consectetur nisi ornare, mattis turpis sed, sagittis ipsum. </p>
					<a className="btn btn-white mt-3" href="/">Link</a>
				</div>
			</div>
		</div>

	);


};

export default ImgTxtCTABG;

