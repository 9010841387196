// figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4478%3A33755&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import classNames from 'classnames';
import { ReactComponent as IconMember } from 'assets/icons/icon-member.svg';
import { ReactComponent as IconProvider } from 'assets/icons/icon-provider.svg';
import { ReactComponent as IconProducer } from 'assets/icons/icon-producer.svg';
import { ReactComponent as IconEmployer } from 'assets/icons/icon-employer.svg';

const ZipCodeHeader = () => {

	const [user, setUser] = React.useState<string>('');

	return (
		<div className="bg-tertiary-light py-4 py-lg-6">
			<div className="container">
				<div className="row">
					<div className="col-lg-7 pb-4">
						<h1>Welcome to Regence Dental</h1>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</div>
				</div>
			</div>
			<div className="container bg-white py-4 px-5">
				<div className="row">
					<div className="col-lg-12">
						<h2 className="pb-4">Let's get started</h2>
						<h3>I'm a:</h3>
					</div>
					<div className="col-lg-3 pb-4 pe-lg-2">
						<button
							onClick={() => setUser('member')}
							className={classNames('card w-100', user === 'member' && 'zip-checked')}>
							<div className="card-body text-start p-3">
								<div className="d-flex">
									<IconMember className="mb-3" />
									<div className="form-check position-absolute">
										<input
											className={classNames('form-check-input', user === 'member' && 'zip-checkbox-checked')}
											type="checkbox" name="memberCheck" id="memberCheck" />
									</div>
								</div>
								<label className="form-check-label" htmlFor="memberCheck">
									<h5 className="card-title">Member</h5>
								</label>
							</div>
						</button>
					</div>
					<div className="col-lg-3 pb-4 px-lg-2">
						<button
							onClick={() => setUser('provider')}
							className={classNames('card w-100', user === 'provider' && 'zip-checked')}>
							<div className="card-body text-start p-3">
								<div className="d-flex">
									<IconProvider className="mb-3" />
									<div className="form-check position-absolute">
										<input
											className={classNames('form-check-input', user === 'provider' && 'zip-checkbox-checked')}
											type="checkbox" name="providerCheck" id="providerCheck" />
									</div>
								</div>
								<label className="form-check-label" htmlFor="providerCheck">
									<h5 className="card-title">Provider</h5>
								</label>
							</div>
						</button>
					</div>
					<div className="col-lg-3 pb-4 px-lg-2">
						<button
							onClick={() => setUser('producers')}
							className={classNames('card w-100', user === 'producers' && 'zip-checked')}>
							<div className="card-body text-start p-3">
								<div className="d-flex">
									<IconProducer className="mb-3" />
									<div className="form-check position-absolute">
										<input
											className={classNames('form-check-input', user === 'producers' && 'zip-checkbox-checked')}
											type="checkbox" name="producerCheck" id="producerCheck" />
									</div>
								</div>
								<label className="form-check-label" htmlFor="producerCheck">
									<h5 className="card-title">Producer</h5>
								</label>
							</div>
						</button>
					</div>
					<div className="col-lg-3 pb-4 ps-lg-2">
						<button
							onClick={() => setUser('employers')}
							className={classNames('card w-100', user === 'employers' && 'zip-checked')}>
							<div className="card-body text-start p-3">
								<div className="d-flex">
									<IconEmployer className="mb-3" />
									<div className="form-check position-absolute">
										<input
											className={classNames('form-check-input', user === 'employers' && 'zip-checkbox-checked')}
											type="checkbox" name="employerCheck" id="employerCheck" />
									</div>
								</div>
								<label className="form-check-label" htmlFor="employerCheck">
									<h5 className="card-title">Employer</h5>
								</label>
							</div>
						</button>
					</div>
					<div className="col-lg-7">
						<label htmlFor="zipdcodeinput" className="form-label">I'm located in:</label>
						<input type="text" className="form-control" id="zipdcodeinput" placeholder="Enter zip code" />
						<button type="submit"
							className="btn btn-primary mt-4">
							Get started
						</button>
					</div>
				</div>
			</div >
		</div >
	);
};

export default ZipCodeHeader;

