// figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3750%3A4361&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconTick } from 'assets/icons/icon-tick.svg';
import mockupimg from 'assets/images/threeimgmockup.png';

const ImgLeftTxtCTA = () => {
	return (
		<div className="container">
			<div className="row my-4 align-items-center">
				<div className="col-lg-6 order-last order-lg-first mt-4 mt-lg-0">
					<img className="object-fit-cover" src={mockupimg} alt="This is a mock up image" />
				</div>
				<div className="col-lg-6 mt-4 mt-lg-7 order-first order-lg-last">
					<h2>Title title title</h2>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Quisque bibendum diam id nibh rutrum, non tristique mi iaculis.Fusce ut mattis tellus.Nunc eu augue purus.</p>
					<p>Donec consectetur nisi ornare, mattis turpis sed, sagittis ipsum. Donec consectetur nisi ornare, mattis turpis sed, sagittis ipsum.</p>
					<p className="mb-0">
						<IconTick className="me-3" />
						Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					<a className="btn btn-primary mt-4" href="/">Link</a>
				</div>
			</div>
		</div>

	);


};

export default ImgLeftTxtCTA;

