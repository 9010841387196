// figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4476%3A33368&t=xwXaLDTUmv97GJfu-4

import React from 'react';

const SearchResults = () => {
	return (
		<React.Fragment>
			<div className="bg-quinary-light">
				<div className="container">
					<div className="row">
						<div className="ps-3 py-5">
							<h2 className="">10 results for "documents"</h2>
						</div>
					</div>
				</div>
			</div><div className="container">
				<div className="row">
					<div className="ps-3 p-0">
						<div className="py-4">
							<a className="text-decoration-none text-dark" href="">
								<h4 className="mb-2">Search suggestion title goes here</h4>
								<p>A snippet of the content goes here</p>
							</a>
							<hr className="pb-4 mb-n2" />
							<a className="text-decoration-none text-dark" href="">
								<h4 className="mb-2">Search suggestion title goes here</h4>
								<p>A snippet of the content goes here</p>
							</a>
							<hr className="pb-4 mb-n2" />
							<a className="text-decoration-none text-dark" href="">
								<h4 className="mb-2">Search suggestion title goes here</h4>
								<p>A snippet of the content goes here</p>
							</a>
							<hr className="pb-4 mb-n2" />
							<a className="text-decoration-none text-dark" href="">
								<h4 className="mb-2">Search suggestion title goes here</h4>
								<p>A snippet of the content goes here</p>
							</a>
							<hr className="pb-4 mb-n2" />
							<a className="text-decoration-none text-dark" href="">
								<h4 className="mb-2">Search suggestion title goes here</h4>
								<p>A snippet of the content goes here</p>
							</a>
							<hr className="pb-4 mb-n2" />
							<a className="text-decoration-none text-dark" href="">
								<h4 className="mb-2">Search suggestion title goes here</h4>
								<p>A snippet of the content goes here</p>
							</a>
							<hr className="pb-4 mb-n2" />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SearchResults;



