//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=3864%3A12811&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconMockUp } from 'assets/icons/icon-mockup.svg';

const GridThree = () => {
	return (
		<div className="py-4">
			<div className="container">
				<div className="row g-0">
					<div className="col-lg-6">
						<h2 className="mb-0">Benefits</h2>
						<p className="py-3 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
						<a className="btn btn-primary" href="/">Enroll now</a>
					</div>
				</div>
				<div className="row pt-4 g-0">
					<div className="col-lg-4 pe-lg-2 mt-3 mt-lg-0">
						<div className="bg-white h-100 d-flex flex-column align-items-start border px-3">
							<IconMockUp className="mt-4 mx-0 icon-card-size" />
							<h4 className="py-3 mb-0">News article title</h4>
							<p className="pb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							<a href="#" className="btn btn-outline-primary mt-auto mb-3">Learn More</a>
						</div>
					</div>
					<div className="col-lg-4 px-lg-2 mt-3 mt-lg-0">
						<div className="bg-white h-100 d-flex flex-column align-items-start border px-3">
							<IconMockUp className="mt-4 mx-0 icon-card-size" />
							<h4 className="py-3 mb-0">News article title</h4>
							<p className="pb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							<a href="#" className="btn btn-outline-primary mt-auto mb-3">Learn More</a>
						</div>
					</div>
					<div className="col-lg-4 ps-lg-2 mt-3 mt-lg-0">
						<div className="bg-white h-100 d-flex flex-column align-items-start border px-3">
							<IconMockUp className="mt-4 mx-0 icon-card-size" />
							<h4 className="py-3 mb-0">News article title</h4>
							<p className="pb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							<a href="#" className="btn btn-outline-primary mt-auto mb-3">Learn More</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GridThree;

