/* eslint-disable max-len */
import React from 'react';
import logo from 'assets/images/regence_logo_mock.png';
import { ReactComponent as IconFacebook } from 'assets/icons/icon-facebook.svg';
import { ReactComponent as IconTwitter } from 'assets/icons/icon-twitter.svg';
import { ReactComponent as IconLinkedin } from 'assets/icons/icon-linkedin.svg';
import { ReactComponent as IconYoutube } from 'assets/icons/icon-youtube.svg';
import { ReactComponent as IconInstagram } from 'assets/icons/icon-instagram.svg';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

const PageFooter: React.FC = () => {
	const [showActive, setShowActive] = React.useState<string>('members');

	const location = useLocation();
	const pathname = location.pathname;


	React.useEffect(() => {
		const topnav = ['members', 'providers', 'producers', 'employers', 'contact'];
		topnav.forEach(v => pathname.includes(v) && setShowActive(v));
	}, [pathname]);


	return (
		<footer className="bg-gray-100">
			<div className="container">
				<div className="row">
					<div className="col-lg-2">
						<img src={logo} alt="Regence State" className="img-fluid" />
					</div>
					<div className="col-lg-3 mt-4">
						<h4>Quick Links</h4>
						{/*Members */}
						<ul className={classNames('footer-links list-unstyled', showActive === 'members' && 'd-block')} >
							<li className="mt-2"><a href="/">Plans</a></li>
							<li className="mt-2"><a href="/">Find a dentist</a></li>
							<li className="mt-2"><a href="/">Resources</a></li>
							<li className="mt-2"><a href="/">Contact us</a></li>
						</ul>
						{/*Providers */}
						<ul className={classNames('footer-links list-unstyled', showActive === 'providers' && 'd-block')} >
							<li className="mt-2"><a href="/">Tools and resources</a></li>
							<li className="mt-2"><a href="/">Join network</a></li>
							<li className="mt-2"><a href="/">News</a></li>
						</ul>
						{/*Producers */}
						<ul className={classNames('footer-links list-unstyled', showActive === 'producers' && 'd-block')} >
							<li className="mt-2"><a href="/">Footer Link</a></li>
						</ul>
						{/*Employers */}
						<ul className={classNames('footer-links list-unstyled', showActive === 'employers' && 'd-block')} >
							<li className="mt-2"><a href="/">Footer Link</a></li>
						</ul>
						{/*Contact Us */}
						<ul className={classNames('footer-links list-unstyled', showActive === 'contact' && 'd-block')} >
							<li className="mt-2"><a href="/">Footer Link</a></li>
						</ul>
						<ul className="list-unstyled">
							<li className="mt-3"><h4>Connect with us</h4></li>
							<li className="mt-2">
								<a href="#">
									<IconFacebook className="me-2" />
								</a>
								<a href="#">
									<IconInstagram className="me-2" />
								</a>
								<a href="#">
									<IconLinkedin className="me-2" />
								</a>
								<a href="#">
									<IconTwitter className="me-2" />
								</a>
								<a href="#">
									<IconYoutube className="" />
								</a>
							</li>
						</ul>
					</div>
					<div className="col-lg-7 mt-4">
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque bibendum diam id nibh rutrum, non tristique mi iaculis. Fusce ut mattis tellus. Nunc eu augue purus. Donec consectetur nisi ornare, mattis turpis sed, sagittis ipsum. Pellentesque eget enim diam. In vehicula, nibh vitae porttitor sollicitudin, mauris ex faucibus felis, in suscipit nisi mauris sed nisl. Aenean pharetra venenatis efficitur.</p>
						<p>Mauris id neque gravida, tincidunt velit sit amet, blandit urna. Quisque nulla magna, tempor at porttitor sed, pharetra id justo. Maecenas non magna arcu. Morbi eu elit ut sapien condimentum cursus. Nam tempus pretium tellus, ac facilisis metus eleifend eu. Donec egestas finibus erat sed sagittis. Suspendisse potenti.</p>
					</div>

				</div>
				<div className="row mt-6">
					<div className="col-lg-8">
						<p>© 2022 Regence BlueCross BlueShield of Oregon. Regence BlueCross BlueShield of Oregon is an Independent Licensee of the Blue Cross and Blue Shield Association.</p>
					</div>
					<div className="col-lg-4 d-flex">
						<a className="ms-lg-auto footer-link" href="/">Terms and Conditions</a>
						<span className="px-1">|</span>
						<a href="/" title="" className="footer-link">Privacy Statement</a>
					</div>

				</div>
			</div>
		</footer>
	);
};

export default PageFooter;
