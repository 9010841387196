import React from 'react';
import classNames from 'classnames';
import { ReactComponent as IconLock } from 'assets/icons/icon-lock.svg';
import { ReactComponent as IconPen } from 'assets/icons/icon-pen.svg';
import { ReactComponent as IconSearch } from 'assets/icons/icon-search.svg';
import { ReactComponent as IconSearchWhite } from 'assets/icons/icon-search-white.svg';
import { ReactComponent as IconPDF } from 'assets/icons/icon-pdf.svg';
import { ReactComponent as IconNewTab } from 'assets/icons/icon-open-in-new.svg';
import { ReactComponent as IconArrowRight } from 'assets/icons/icon-arrow-right.svg';
import { ReactComponent as IconHome } from 'assets/icons/icon-home.svg';
import { ReactComponent as IconChevronLeft } from 'assets/icons/icon-chevron-left.svg';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';
import { useLocation } from 'react-router-dom';
import logo from 'assets/images/regence_logo_mock.png';

const NavBar = () => {
	const [showActive, setShowActive] = React.useState<string>('members');
	console.log(showActive);

	const location = useLocation();
	const pathname = location.pathname;
	const [showSearch, setShowSearch] = React.useState<boolean>(false);
	const [showZip, setShowZip] = React.useState<boolean>(false);
	let width = window.innerWidth;
	const { body } = document;
	const navClass = 'nav-open';

	const toggleMenu = () => {
		const subNavbar = document.getElementById('v-tabContent');
		const navbar = document.getElementById('navbarSupportedContent');
		const collapseClass = 'collapse';
		body.classList.toggle(navClass);
		navbar && navbar.classList.toggle(collapseClass);
		subNavbar && !subNavbar.classList.contains('collapsed') && subNavbar.classList.add('collapsed');
	};
	const toggleSubMenu = () => {
		const subNavbar = document.getElementById('v-tabContent');
		subNavbar && subNavbar.classList.toggle('collapsed');
	};

	const showMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, menu: string) => {
		if (body.classList.contains(navClass)) {
			toggleSubMenu();
			e.preventDefault();
		}
		setShowActive(menu);
	};
	const toggleZip = () => {
		setShowZip(v => !v);
		body.classList.toggle(navClass);
	};
	const toggleSearch = () => {
		setShowSearch(v => !v);
		body.classList.toggle(navClass);
	};


	React.useEffect(() => {
		if (width > 768) {
			const topnav = ['members', 'providers', 'producers', 'employers', 'contact'];
			topnav.forEach(v => pathname.includes(v) && setShowActive(v));
		}
	}, [pathname]);

	return (
		<React.Fragment>
			<nav className="navbar navbar-expand-lg navbar-light bg-white p-0 sticky-top shadow-sm">
				<div className="container-fluid container-xl">
					<a className="navbar-brand me-0 me-lg-4" href="#">
						<img src={logo} alt="" />
					</a>
					<div className="d-flex d-lg-none justify-content-end align-items-center">
						<button
							className="btn btn-icon-dark text-regular"
							data-bs-toggle="modal"
							data-bs-target="#zipModal"
							onClick={toggleZip}
						>
							<span className="btn-content text-regular">
								ZIP 12345
								<IconPen className="icon-sm ms-2" />
							</span>
						</button>
						<div className="border-start border-gray-400 py-3" />
						<button
							className="btn btn-icon-dark"
							data-bs-toggle="modal"
							data-bs-target="#searchModal"
							onClick={toggleSearch}
						>
							<span className="btn-content">
								<IconSearch className="icon-sm" />
							</span>
						</button>
						<div className="border-start border-gray-400 py-3" />
						<button
							onClick={toggleMenu}
							type="button"
							className="btn btn-icon-dark"
							data-bs-toggle="collapse"
							data-bs-target="#navbarSupportedContent"
							aria-controls="navbarSupportedContent"
							aria-expanded="false"
							aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
					</div>
					<div className="navbar-collapse align-items-stretch flex-column collapse" id="navbarSupportedContent">
						<div className="d-flex flex-column-reverse flex-lg-row nav-border" id="navbar">
							<ul className="navbar-nav me-auto mb-lg-0">
								<li className="nav-item">
									<a className={classNames('nav-link nav-top-link h4 mb-0 has-sub-menu', showActive === 'members' && 'active')}
										aria-current="page"
										id="v-1-tab"
										data-bs-toggle="tab"
										data-bs-target="#v-1"
										role="tab"
										aria-controls="v-1"
										href="/members"
										aria-selected="true"
										onClick={(e) => showMenu(e, 'members')}
									>
										Members
									</a>
								</li>
								<li className="nav-item">
									<a className={classNames('nav-link nav-top-link h4 mb-0 has-sub-menu', showActive === 'providers' && 'active')}
										aria-current="page"
										id="v-2-tab"
										data-bs-toggle="tab"
										data-bs-target="#v-2"
										role="tab"
										aria-controls="v-2"
										href="/providers"
										aria-selected="true"
										onClick={(e) => showMenu(e, 'providers')}
									>
										Providers
									</a>
								</li>
								<li className="nav-item">
									<a className={classNames('nav-link nav-top-link h4 mb-0 has-sub-menu', showActive === 'producers' && 'active')}
										aria-current="page"
										id="v-3-tab"
										data-bs-toggle="tab"
										data-bs-target="#v-3"
										role="tab"
										aria-controls="v-3"
										href="/producers"
										aria-selected="true"
										onClick={(e) => showMenu(e, 'producers')}
									>
										Producers
									</a>
								</li>
								<li className="nav-item">
									<a className={classNames('nav-link nav-top-link h4 mb-0 has-sub-menu', showActive === 'employers' && 'active')}
										aria-current="page"
										id="v-4-tab"
										data-bs-toggle="tab"
										data-bs-target="#v-4"
										role="tab"
										aria-controls="v-4"
										href="/employers"
										aria-selected="true"
										onClick={(e) => showMenu(e, 'employers')}
									>
										Employers
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link nav-top-link h4 mb-0" href="/contact-us">Contact us</a>
								</li>
							</ul>
							<div className="d-flex flex-fill align-items-center justify-content-between justify-content-lg-end px-3 px-xl-0 border-bottom border-xl-0">
								<a className="navbar-brand d-block d-lg-none" href="#">
									<img src={logo} alt="" />
								</a>
								<button className="btn btn-icon-primary my-auto d-lg-none">
									<span className="btn-content"><IconLock className="pe-2" />Log in</span>
								</button>
								<button
									className="btn btn-icon-dark text-regular d-none d-lg-block"
									data-bs-toggle="modal"
									data-bs-target="#zipModal"
									onClick={toggleZip}
								>
									<span className="btn-content text-regular">
										ZIP 12345
										<IconPen className="icon-sm ms-2" />
									</span>
								</button>
								<div className="vr my-3 d-none d-lg-block"></div>
								<button
									className="d-none d-lg-block btn btn-icon-dark" data-bs-toggle="modal"
									data-bs-target="#searchModal"
									onClick={toggleSearch}
								>
									<span className="btn-content text-regular">
										<IconSearch className="icon-sm" />
										<span className="ps-2 d-none d-xl-inline">Search</span>
									</span>
								</button>
								<button type="button" onClick={toggleMenu} className="btn-close d-block d-lg-none" data-bs-dismiss="offcanvas" aria-label="Close" />
							</div>
						</div>
						<div className="tab-content nav-tab-content position-relative flex-fill sub-navbar-collapse collapsed" id="v-tabContent">
							<button className="btn btn-icon px-3 py-2 d-flex align-items-center d-lg-none" onClick={toggleSubMenu}>
								<div className="border-end border-gray-700 d-flex align-items-center py-2 pe-2 me-2">
									<IconHome className="icon-sm me-1" />
								</div>
								<IconChevronLeft className="icon-md me-2 text-gray-700" />
								<span className="text-decoration-none text-regular fw-normal text-md text-gray-700 mb-0">Return</span>
							</button>
							{/*Members Nav */}
							<div
								className={classNames('tab-pane flex-column flex-lg-row', showActive === 'members' && 'd-flex')}
								id="v-1"
								role="tabpanel"
								aria-labelledby="v-1-tab"
							>
								<h5 className="d-lg-none p-3 mb-0 bg-quinary-light">Members</h5>
								<ul className="navbar-nav me-auto mb-2 mb-lg-0 d-lg-flex">
									<li className="nav-item d-lg-none">
										<a className="nav-link" href="/employers">Members Home</a>
									</li>
									<li className="nav-item">
										<span
											className="nav-link dropdown-toggle"
											id="navbarDropdown"
											role="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											Plans
										</span>
										<ul className="dropdown-menu rounded-0 py-lg-4 row" aria-labelledby="navbarDropdown">
											<li className="col-lg-3">
												<a className="nav-link p-0" href="/members-plan"><h5>Plans page</h5></a>
												<p className="mb-0">One sentence description about this section</p>
											</li>
											<li className="col-lg-3">
												<a className="nav-link p-0" href="/members-tools"><h5>Tools page</h5></a>
												<p>One sentence description about this section</p>
												<a className="d-block mb-3 view-doc" href="#">
													<IconPDF className="icon-md me-1" />
													Benefit summary
													<IconNewTab className="icon-sm ms-1" />
												</a>
												<a className="d-block mb-3 view-doc" href="#">
													<IconPDF className="icon-md me-1" />
													Benefit summary
													<IconNewTab className="icon-sm ms-1" />
												</a>
											</li>
											<li className="col-lg-3">
												<a className="nav-link p-0" href="/members-program"><h5>Program Page</h5></a>
												<p>One sentence description about this section</p>
												<a className="d-block mb-3 view-doc" href="#">
													<IconPDF className="icon-md me-1" />
													Benefit summary
													<IconNewTab className="icon-sm ms-1" />
												</a>
												<a className="d-block mb-3 view-doc" href="#">
													<IconPDF className="icon-md me-1" />
													Benefit summary
													<IconNewTab className="icon-sm ms-1" />
												</a>
											</li>
											<li className="col-lg-3">
												<a className="nav-link p-0" href="/speciality"><h5>Special Components</h5></a>
												<p>One sentence description about this section</p>
												<a className="d-block mb-3 view-doc" href="#">
													<IconPDF className="icon-md me-1" />
													Benefit summary
													<IconNewTab className="icon-sm ms-1" />
												</a>
												<a className="d-block mb-3 view-doc" href="#">
													<IconPDF className="icon-md me-1" />
													Benefit summary
													<IconNewTab className="icon-sm ms-1" />
												</a>
											</li>

										</ul>
									</li>
									<li className="nav-item menu-bottom-basic">
										<a
											className="nav-link dropdown-toggle"
											href="#"
											id="navbarDropdown"
											role="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											Find a dentist
										</a>
										<ul className="dropdown-menu rounded-0 p-lg-3" aria-labelledby="navbarDropdown">
											<li>
												<a className="dropdown-item h6 mb-0 px-3 py-lg-3" href="#">
													Find a dentist
												</a>
											</li>
											<li>
												<a className="dropdown-item h6 mb-0 px-3 py-lg-3" href="#">
													Emergency
												</a>
											</li>
										</ul>
									</li>
									<li className="nav-item">
										<a
											className="nav-link dropdown-toggle"
											href="#"
											id="navbarDropdown"
											role="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											Programs
										</a>
										<ul className="dropdown-menu row rounded-0 py-0 p-lg-4" aria-labelledby="navbarDropdown">
											<li className="col-lg-6 mb-lg-3">
												<a className="nav-link h6 mb-0 p-0" href="#">Oral Health and Overall Health</a>
												<a href="#" className="d-block mb-0">Enroll</a>
											</li>
											<li className="col-lg-6 mb-lg-3">
												<a className="nav-link h6 mb-0 p-0" href="#">Blue Dental Loyalty</a>
											</li>
											<li className="col-lg-6">
												<a className="nav-link h6 mb-0 p-0" href="#">Maximum Rollover</a>
											</li>
											<li className="col-lg-6">
												<a className="nav-link h6 mb-0 p-0" href="#">Discount Programs</a>
											</li>
										</ul>
									</li>
									<li className="nav-item ">
										<a
											className="nav-link dropdown-toggle"
											href="#"
											id="navbarDropdown"
											role="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											Resources
										</a>
										<ul
											className="dropdown-menu rounded-0 py-0 p-lg-3 row"
											aria-labelledby="navbarDropdown"
										>
											<li className="col-lg-6">
												<a className="nav-link p-0 mb-2" href="#"><h5>New Members Guide</h5></a>
												<a className="d-block mb-2 mb-2" href="#">Enroll</a>
												<a className="d-block mb-2 mb-3" href="/resource">Resource page</a>
												<a className="nav-link p-0 mb-2" href="#"><h5>Health and Wellness Tips</h5></a>
												<a className="nav-link p-0  mb-2" href="#"><h5>Individual Plan Documents</h5></a>
												<div className="">
													<a className="nav-link mb-2 p-0" href="#"><h5>Dental Insurance 101</h5></a>
													<a className="d-block mb-2" href="#">Understanding Your Dental Plan</a>
													<a className="d-block mb-2" href="#">Working With Your Dentist</a>
													<a className="d-block mb-2" href="#">Dental Conditions and Treatment</a>
												</div>
											</li>
											<li className="col-lg-6">
												<div className="bg-tertiary-light">
													<h5 className=" p-3">Maximum Rollover</h5>
												</div>
												<div className="px-3 pb-4">
													<a className="d-block mb-2" href="#">New Members Plan</a>
													<a className="d-block mb-2" href="#">How Your Dental Plan Works</a>
													<a className="d-block mb-2" href="#">The importance of dental insurance</a>
													<a className="d-block mb-2 view-all-doc" href="#">View all resources
														<IconArrowRight className="icon-md" />
													</a>
												</div>
												<div className="bg-tertiary-light">
													<h5 className="p-3 ">Discount Programs</h5>
												</div>
												<div className="px-3">
													<a className="d-block mb-3 view-doc" href="#">
														<IconPDF className="icon-md me-1" />
														Benefit summary
														<IconNewTab className="icon-sm ms-1" />
													</a>
													<a className="d-block mb-3 view-doc" href="#">
														<IconPDF className="icon-md me-1" />
														Benefit summary
														<IconNewTab className="icon-sm ms-1" />
													</a>
													<a className="d-block mb-3 view-doc" href="#">
														<IconPDF className="icon-md me-1" />
														Benefit summary
														<IconNewTab className="icon-sm ms-1" />
													</a>
													<a className="d-block mb-2 view-all-doc" href="#">View all documents
														<IconArrowRight className="icon-sm" />
													</a>
												</div>
											</li>
										</ul>
									</li>
								</ul>
							</div>
							{/*Providers Menus */}
							<div
								className={classNames('tab-pane flex-column flex-lg-row', showActive === 'providers' && 'd-flex')}
								id="v-2" role="tabpanel" aria-labelledby="v-2-tab">
								<ul className="navbar-nav me-auto mb-2 mb-lg-0 position-relative flex-fill">
									<li className="nav-item">
										<a
											className="nav-link dropdown-toggle"
											href="#"
											id="navbarDropdown"
											role="button"
											data-bs-toggle="dropdown"
											aria-expanded="false">
											Tools and resources
										</a>
										<ul
											className="dropdown-menu rounded-0 py-0 row"
											aria-labelledby="navbarDropdown"
										>
											<li className="p-lg-4 col-lg-3">
												<a className="nav-link p-0" href="/providers-tools"><h5>Tools page</h5></a>
												<p>One sentence description about this section</p>
											</li>
											<li className="p-lg-4 col-lg-3">
												<a className="nav-link p-0" href="#"><h5>Plan Name</h5></a>
												<p>One sentence description about this section</p>
												<a className="d-block mb-2 view-link" href="#">
													View PDF link
												</a>
												<a className="d-block mb-2 view-link" href="#">
													View PDF link
												</a>
											</li>
											<li className="p-lg-4 col-lg-3">
												<a className="nav-link p-0" href="#"><h5>Plan Name</h5></a>
												<p>One sentence description about this section</p>
												<a className="d-block mb-2 view-link" href="#">
													View PDF link
												</a>
												<a className="d-block mb-2 view-link" href="#">
													View PDF link
												</a>
											</li>
											<li className="p-lg-4  col-lg-3 ">
												<a className="nav-link p-0" href="#"><h5>Plan Name</h5></a>
												<p>One sentence description about this section</p>
												<a className="d-block mb-2 view-link" href="#">
													View PDF link
												</a>
												<a className="d-block mb-2 view-link" href="#">
													View PDF link
												</a>
											</li>
										</ul>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#">
											Join network
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#">
											News
										</a>
									</li>
								</ul>
							</div>
							{/*Producers Menus */}
							<div
								className={classNames('tab-pane flex-column flex-lg-row', showActive === 'producers' && 'd-flex')}
								id="v-3" role="tabpanel" aria-labelledby="v-3-tab">
								<ul className="navbar-nav me-auto mb-2 mb-lg-0 position-relative flex-fill">
									<h5 className="d-block d-lg-none p-3 mb-0">Producers</h5>
									<li className="nav-item d-block d-lg-none ">
										<a className="nav-link" href="/producers">Producers Home</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#">
											Producers Nav
										</a>
									</li>
								</ul>
							</div>
							{/*Employers Menus */}
							<div
								className={classNames('tab-pane flex-column flex-lg-row', showActive === 'employers' && 'd-flex')}
								id="v-4" role="tabpanel" aria-labelledby="v-4-tab"
							>
								<ul className="navbar-nav me-auto mb-2 mb-lg-0 position-relative flex-fill">
									<h5 className="d-lg-none p-3 mb-0">Employers</h5>
									<li className="nav-item d-lg-none">
										<a className="nav-link" href="/employers">Employers Home</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#">
											Employers Nav
										</a>
									</li>
								</ul>
							</div>
							<button className="d-none d-lg-flex btn btn-outline-primary position-absolute top-0 bottom-0 end-0 my-2 px-3 align-items-center navbar-btn" type="button">
								<IconLock className="pe-2" />Log in
							</button>
						</div>
					</div>
				</div>
			</nav>





















			{/* Modal */}
			<div
				className={classNames('modal fade', showSearch === true && 'd-flex show')}
				id="searchModal"
				tabIndex={-1}
				aria-labelledby="searchModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-fullscreen">
					<div className="modal-content">
						<div className="bg-quinary-light">
							<div className="container">
								<div className="row">
									<div className="modal-header ps-3 pt-5 p-0 border-0">
										<h2 className="modal-title" id="searchModalLabel">Search Regence Dental</h2>
										<button type="button" className="btn btn-icon-dark" data-bs-dismiss="modal" aria-label="Close"
											onClick={toggleSearch}>
											<span className="btn-content">
												<IconClose />
											</span>
										</button>
									</div>
									<div className="input-group mt-3 mb-5">
										<input
											type="text"
											className="form-control"
											placeholder=""
											aria-label="search term" aria-describedby="button-search"
										/>
										<a className="btn btn-primary" type="button" id="button-search" href="/search"><IconSearchWhite className="pe-2" />Search</a>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							<div className="row">
								<div className="modal-body ps-3 p-0">
									<div className="py-3">
										<h3>Try searching for</h3>
										<button className="btn btn-outline-primary me-3 mb-2" type="button" id="button-search">Documents</button>
										<button className="btn btn-outline-primary me-3 mb-2" type="button" id="button-search">Popular Search Term</button>
										<button className="btn btn-outline-primary me-3 mb-2" type="button" id="button-search">Popular Search Term</button>
									</div>
									<div className="py-4">
										<a className="text-decoration-none text-dark" href="">
											<h4 className="mb-2">Search suggestion title goes here</h4>
											<p>A snippet of the content goes here</p>
										</a>
										<hr className="pb-4 mb-n2" />
										<a className="text-decoration-none text-dark" href="">
											<h4 className="mb-2">Search suggestion title goes here</h4>
											<p>A snippet of the content goes here</p>
										</a>
										<hr className="pb-4 mb-n2" />
										<a className="text-decoration-none text-dark" href="">
											<h4 className="mb-2">Search suggestion title goes here</h4>
											<p>A snippet of the content goes here</p>
										</a>
										<hr className="pb-4 mb-n2" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className={classNames('modal fade', showZip === true && 'd-flex show')}
				id="zipModal" tabIndex={-1}
				aria-labelledby="ezipModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog zip-modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-header pt-4 pb-3 border-0">
							<h3 className="modal-title" id="zipModalLabel">Enter your location</h3>
						</div>
						<div className="modal-body pb-3 pt-0">
							<div>
								<label htmlFor="zipdcodeinput" className="form-label fw-bold">I'm located in:</label>
								<input
									type="text"
									className="form-control rounded-0"
									id="zipdcodeinput"
									placeholder="Enter zip code" />
							</div>
						</div>
						<div className="modal-footer justify-content-start mt-0 pt-0">
							<button type="button"
								className="btn btn-primary"
								onClick={toggleZip}>
								Save
							</button>
							<button type="button"
								className="btn btn-outline-primary"
								onClick={toggleZip}>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default NavBar;