//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4413%3A28864&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconPhone } from 'assets/icons/icon-phone.svg';

const GeneralContact = () => {
	return (
		<div className="container">
			<div className="row py-4 py-lg-6 align-items-center">
				<div className="col-lg-6 mb-4 mb-lg-0">
					<div>
						<h2 className="mb-2">General Contact Section Here</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</div>
					<div>
						<h4 className="mb-2">Title Here</h4>
						<IconPhone className="me-2" /><a className="my-3 pb-2" href="tel:123-456-7890">123-456-7890</a>
					</div>
					<div className="pt-4 mt-n2">
						<h4 className="mb-2">Title Here</h4>
						<IconPhone className="me-2" /><a className="my-3 pb-2" href="tel:123-456-7890">123-456-7890</a>
					</div>
					<hr className="my-4" />
					<div>
						<h2 className="mb-2">General Contact Section Here</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</div>
					<div>
						<h4 className="mb-2">Title Here</h4>
						<IconPhone className="me-2" /><a className="my-3 pb-2" href="tel:123-456-7890">123-456-7890</a>
					</div>
					<div className="pt-4 mt-n2">
						<h4 className="mb-2">Title Here</h4>
						<IconPhone className="me-2" /><a className="my-3 pb-2" href="tel:123-456-7890">123-456-7890</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GeneralContact;

