import React from 'react';
import { Page } from 'components/core';
import { LandingHeader, ImgTxtCTABG, RelatedArticles, TxtWithQuickLinks } from 'screens/components';

const ResourcesLandingPage = () => {

	return (
		<Page>
			<Page>
				<LandingHeader />
				<TxtWithQuickLinks />
				<RelatedArticles />
				<ImgTxtCTABG />
			</Page>
		</Page>
	);
};

export default ResourcesLandingPage;
