//figma link: https://www.figma.com/file/9ZUGR50M1R3lx8Jpr5cCIP/%5BUSAble---Regence-Dental%5D-Design-Language?node-id=4484%3A34812&t=xwXaLDTUmv97GJfu-4

import React from 'react';
import { ReactComponent as IconTick } from 'assets/icons/icon-alert-tick.svg';
import { ReactComponent as IconInfo } from 'assets/icons/icon-alert-info.svg';
import { ReactComponent as IconWarning } from 'assets/icons/icon-alert-warning.svg';
import { ReactComponent as IconError } from 'assets/icons/icon-alert-error.svg';

const Alerts = () => {
	return (
		<React.Fragment>
			<div className="container">
				<div className="alert alert-info my-3" role="alert">
					<div className="row">
						<div className="col-lg-6">
							<h4 className="alert-heading"><IconInfo className="flex-shrink-0 me-2 mt-n1" />Disclaimer example</h4>
							<p className="ms-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="alert alert-success my-3" role="alert">
					<div className="row">
						<div className="col-lg-6">
							<h4 className="alert-heading"><IconTick className="flex-shrink-0 me-2 mt-n1" />Disclaimer example</h4>
							<p className="ms-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
						</div>
					</div>
				</div>
			</div>


			<div className="container">
				<div className="alert alert-warning my-3" role="alert">
					<div className="row">
						<div className="col-lg-6">
							<h4 className="alert-heading"><IconWarning className="flex-shrink-0 me-2 mt-n1" />Disclaimer example</h4>
							<p className="ms-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="alert alert-danger my-3" role="alert">
					<div className="row">
						<div className="col-lg-6">
							<h4 className="alert-heading"><IconError className="flex-shrink-0 me-2 mt-n1" />Disclaimer example</h4>
							<p className="ms-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
						</div>
					</div>
				</div>
			</div></React.Fragment>
	);
};

export default Alerts;



